/* font */
@font-face {
    font-family: roboto-regular;
    src: url('fonts/opensans/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: cerebri-sans-regular;
    src: url('fonts/CerebriSans-Regular.woff2') format('woff2'),
        url('fonts/CerebriSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
:root {
    // --primary-font: "roboto-regular", sans-serif ;
    // --alt-font:"cerebri-sans-regular",  sans-serif;
    --base-color: rgb(248, 159, 50);
    --primary-color: #213382 ;
    --secondary-color: rgb(116,68,49);
    --third-color:  rgb(244, 215, 180);
    --dark-gray: #232323;
    --medium-gray: #58574B;
    --very-light-gray: #eceff1;

    // --bs-link-color:  rgb(248, 159, 50) !important;
}

.accordion {
    --bs-accordion-active-bg: rgb(248, 159, 50) !important;
    --bs-accordion-btn-focus-border-color: #fff !important;
    --bs-accordion-border-color: #fff !important;
    --bs-accordion-border-width: 0 !important;
    --bs-accordion-btn-focus-box-shadow: none !important;

}
.text-primary {
    color: var(--primary-color) !important
}
.bg-primary {
    background-color: var(--primary-color) !important;
}
.bg-secondary-color {
    background-color: var(--secondary-color);
}
.bg-third-color {
    background-color: rgb(244, 215, 180);
}


/* bg gradient color */
.bg-gradient-pink-orchid {
    background-image: linear-gradient(to right top, #e958a1, #e15aae, #d15ec7, #c561db, #bf63e5);
}
.bg-dark-haiti {
    background-color: #2e2b39;
}
.bg-floral-white {
    background-color: #fff8e7;
}
.bg-licorice-blue {
    background-color: #585d72;
}
.bg-black-pearl-blue {
    background-color: #232530;
}
.bg-black-pearl-blue-dark {
    background-color: #1f212a;
}
.bg-linen{
    background-color: #fdedea;
}
.bg-oxford-blue{
    background-color: #262D38;
}

.page-stick-link {
    background-color: var(--base-color);
}
.page-stick-link:hover {
    background: var(--very-light-gray);
    color: var(--base-color) !important;
}

.text-justify {
    text-align: justify;
}
