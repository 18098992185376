
/* Datatable */
table.dataTable[table-type=table-quy] td:nth-child(1) {
  width: 40%;
}

table.dataTable[table-type=table-quy] th:nth-child(1) {
  width: 40%;
}

@media (max-width: 768px) {
  table.dataTable[table-type=table-quy] th:nth-child(1) {
    width: 40%;
  }
}


/* Thiết lập chiều rộng cố định cho cột đầu tiên khi ở chế độ responsive */
// @media (max-width: 768px) {
//     table.dataTable[table-type = 'table-quy'] th:nth-child(1) {
//         width: 200px;
//     }
//     table.dataTable[table-type = 'table-quy'] th:nth-child(1) .ten-van-ban {
//         width: 200px;
//         white-space: normal;
//         word-wrap: break-word;

//     }
// }
.px200 {
    width: 200px;
    max-width: 200px;
    word-wrap: break-word;
}

/*--- lich su phat trien ---*/
.image-card-box {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border: none;

    &:hover {
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.4);
        transform: scale(1.03);
    }

    .timeline {
        position: relative;
        margin-left: 50px;
        margin-right: 50px;

        &::before {
            content: '';
            background: #C5CAE9;
            ;
            width: 5px;
            height: 95%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.timeline {
    position: relative;
    margin-left: 50px;
    margin-right: 50px;

    &::before {
        content: '';
        background: #C5CAE9;
        ;
        width: 5px;
        height: 95%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.timeline-item {
    width: 100%;
    margin-bottom: 20px;

    &:nth-child(even) {
        .timeline-content {
            float: right;
            margin-right: 130px;
            box-shadow: 15px 15px 10px 0px rgb(0, 0, 0, 0.3);

            /* padding: 10px 0px 10px 30px; */
            .date {
                right: auto;
                left: 0;
                border-top-left-radius: 10px;
                border-top-right-radius: 0;
            }

            .timeline-img-header {
                position: relative;
                margin-left: 0px;
                margin-top: 0px;

                img {
                    height: 200px;
                    width: 100%;
                }
            }

            .main-caption {
                top: 0;
                left: 0;
                text-align: left;
                margin-left: 0px;
                padding-right: 0px;
            }

            &::after {
                content: '';
                position: absolute;
                border-style: solid;
                width: 0;
                height: 0;
                top: 30px;
                left: -15px;
                border-width: 10px 15px 10px 0;
                border-color: transparent #C5CAE9 transparent transparent;
            }
        }
    }

    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

.timeline-content {
    position: relative;
    width: 35%;
    margin-left: 130px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    border-radius: 10px;
    background: var(--very-light-gray);
    box-shadow: -15px 15px 10px 0px rgba(0, 0, 0, .3);

    &::after {
        content: '';
        position: absolute;
        border-style: solid;
        width: 0;
        height: 0;
        top: 30px;
        right: -15px;
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent #C5CAE9;
    }

    .timeline-img-header {
        position: relative;
        margin-right: 0px;
        margin-top: 0px;
    }

    .timeline-img-header img {
        height: 200px;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}

.timeline-content .main-caption {
    position: relative;
    text-align: right;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--base-color);

    h5 {
        color: var(--primary-color);
        font-weight: 700;
        font-size: 1.25rem !important;
    }

    p {
        color: var(--black);
    }
}

.timeline-content .main-caption::after {
    position: absolute;
    bottom: 0;
    content: '';
    background: var(--base-color);
    /* background: #fff; */
    width: 100%;
    height: 3px;
    left: 0;
    margin-right: 5px;
}

.timeline-content .sub-caption {
    font-size: large;
    color: var(--black);
}


.timeline-content .date {
    background: var(--base-color);
    display: inline-block;
    color: #fff !important;
    padding: 10px;
    font-weight: bolder;
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 10px;

}

.timeline-img {
    width: 30px;
    height: 30px;
    background: var(--base-color);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-top: 25px;
    margin-left: -15px;
}

blockquote {
    margin-top: 30px;
    color: var(--secondary-color);
    border-left-color: #3F51B5;
    padding: 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #3F51B5;
}

.timeline-item {
    .timeline-img-header {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .4));
        background-size: cover;
    }
}

@media screen and (max-width: 768px) {
    .timeline {
        margin-left: 5px;
        margin-right: 5px;

        &::before {
            left: 20px;
        }

        .timeline-img {
            left: 20px;
        }

        .timeline-content {
            max-width: 100%;
            width: auto;
            margin-left: 70px;
        }

    }

    .timeline-item {

        &:nth-child(even) {
            .timeline-content {
                float: none;
                margin-right: 0;
            }
        }

        &:nth-child(odd) {
            .timeline-content {
                box-shadow: 15px 15px 25px 0px rgb(243, 196, 138, 1);

                &::after {
                    content: '';
                    position: absolute;
                    border-style: solid;
                    width: 0;
                    height: 0;
                    top: 30px;
                    left: -15px;
                    border-width: 10px 15px 10px 0;
                    border-color: transparent #f5f5f5 transparent transparent;
                }
            }

        }
    }
}

/* page su menh tam nhin */
.process-step-style-11:hover .process-step-icon-wrap {
    background-color: var(--secondary-color);
    background-image: none;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1);
}

/* card danh muc  catelogy-card*/

.page-submenu {
    .widgets-icons {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right: 15px;
        padding: 5px;
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        /* background: linear-gradient(45deg, #c9832d, var(--primary)) !important; */
        font-size: 20px;
        color: var(--blue);
        visibility: hidden;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    :hover .widgets-icons {
        visibility: visible;
        opacity: 1;
    }
}


/*---- Nhan su page -------*/
.nhan-su-item {
    img {
        transition: all .3s ease-in-out;
        outline: 0px solid var(--primary-color);
    }

    :hover img {
        outline: 5px solid var(--primary-color);
    }
}

.nhan-su-modal {
    max-height: 400px;
    overflow-y: auto;
}

.nhan-su-detail_content {
    h5 {
        color: var(--base-color);
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    p {
        color: var(--black);
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    ul>li {
        list-style: disc;
        font-size: 1.25rem;
        color: var(--black);
    }

    ul li>h6 {
        font-weight: bold;
        margin-bottom: 0;
        color: var(--black);
        font-size: 1rem;
    }
}

.nhan-su-detail_info_img {
    img {
        width: 60%;
        height: auto;
        border-radius: 50%;
    }
}

.nhan-su-detai_info_name {
    font-size: 20px;
    margin-top: 25px;
    text-transform: capitalize;
    color: var(--primary-color);
}

.nhan-su-detail_info_title {
    font-size: 1rem;
    margin-top: 10px;
    color: var(--black);
}

.nhan-su-chu-chot-style01 {
    box-shadow: 0 20px 20px var(--base-color)
}


/* ----Page phat trien ben vung---*/
.nn-tuan-hoan-phu-pham-animate {
    position: absolute;
    top: 300px;
    left: 0px;
}

// #animatedPath {
//     transform-origin: center; /* Chỉ định điểm neo là trung tâm của đường path */
//     animation: scalePath 2s infinite alternate;
//   }

//   @keyframes scalePath {
//     0% {
//       transform: scale(1); /* Kích thước ban đầu */
//     }
//     100% {
//       transform: scale(1.5); /* Kích thước tăng lên 1.2 lần */
//     }
//   }
.small {
    width: 18px;
    height: 18px;
}

.follow-path {
    position: absolute;
    top: 15px;
    left: 25px;
}

.square {
    pointer-events: none;
    font-size: 14px;
    background-color: #000;
}

/** truyen thong **/
.truyen-thong {
    li {
        // padding: 0 25px;
        margin-right: 20px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        a {
            color: var(--medium-gray);
            border-bottom: 2px solid transparent;
        }

        &:hover,
        &:focus,
        &.active {
            a {
                color: var(--base-color);
                border-color: var(--base-color);
            }
        }
    }
}

/** pagination **/
.pagination-style-01 .page-item.active .page-link {
    background: var(--base-color) !important;
}

/*--image-box */
.image-box-style1 {
    box-shadow: 5px 5px 15px 0px rgb(0, 0, 0, 0.3);
    padding-left: 0;
    padding-right: 0;
    border-radius: 10px;

    .card {
        border-radius: 10px;
        border: none;
    }

    .image-box-body {
        background-color: var(--base-color);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    img {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
}

@media screen and (max-width: 576px) {
    .image-box-style1 {
        .image-box-body {
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        img {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
}

/* image-box-style2 */
.image-box-style2-noidung {

    h4,
    h5,
    h6 {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.25;
        margin-top: 1.25rem;
        margin-bottom: .25rem;
        color: #000;
    }

    ul li {
        list-style: disc;
    }
}

.video-box-style2 {
    box-shadow: 5px 5px 15px 0px rgb(0, 0, 0, 0.3);
    padding-left: 0;
    padding-right: 0;
    border-radius: 10px;

    .card {
        border-radius: 10px;
        border: none;
    }

    .image-box-body {
        background-color: var(--base-color);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    video {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

    }

}

@media screen and (max-width: 576px) {
    .video-box-style2 {
        .image-box-body {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
        }

        video {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 10px;
        }
    }
}

/* -- dung-chung- image-box-style-5-mota */
.image-box-style5-noidung-phai {
    .image-box-style5-bg {
        background-color: var(--base-color);
        margin-left: -100px;
        z-index: 1;
        clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        .image-box-style5-noidung {
            padding: 15px;

            h1,
            h2,
            h3,
            h4 {
                padding-left: 10%;
                font-weight: bolder;
                color: var(--primary-color);
            }

            h1 {
                font-size: 40px;
            }

            h2,
            h3,
            h4,
            h5,
            h6 {
                font-size: 34px;
            }

            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                color: #000;
                /* Loại bỏ bullet points */
                width: 100%;
            }

            li::before {
                content: "\2022";
                // content: counter(item) ". ";
                // counter-increment: item;
                color: #000;
                font-size: 16px;
                margin-right: 5px;
            }

            li {
                text-align: left;
            }

            li:nth-child(1) {
                padding-left: 10%;
            }

            li:nth-child(2) {
                padding-left: 9%;
            }

            li:nth-child(3) {
                padding-left: 7%;
            }

            li:nth-child(4) {
                padding-left: 7%;
            }

            li:nth-child(5) {
                padding-left: 6%;
            }

            li:nth-child(6) {
                padding-left: 5%;
            }

            li:nth-child(7) {
                padding-left: 4%;
            }

            li:nth-child(8) {
                padding-left: 3%;
            }

            li:nth-child(9) {
                padding-left: 2%;
            }

            li:nth-child(10) {
                padding-left: 1%;
            }

        }
    }
}

.image-box-style5-noidung-trai {
    .image-box-style5-hinh-anh {
        margin-left: -10%;

        img {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    .image-box-style5-bg {
        background-color: var(--base-color);
        // /margin-right: 100px;
        z-index: 1;
        clip-path: polygon(0% 0%, 90% 0%, 100% 100%, 00% 100%);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        .image-box-style5-noidung {
            padding: 20px;

            h1,
            h2,
            h3,
            h4 {
                padding-right: 10%;
                padding-left: 5%;
                font-weight: bolder;
                color: var(--primary-color);
            }

            h1 {
                font-size: 40px;
            }

            h2,
            h3,
            h4,
            h5,
            h6 {
                font-size: 34px;
            }

            ul {
                padding-right: 15%;
                margin: 0;
                list-style: none;
                color: #000;
                /* Loại bỏ bullet points */
                width: 100%;
            }

            li::before {
                content: "\2022";
                // content: counter(item) ". ";
                // counter-increment: item;
                color: #000;
                font-size: 16px;
                margin-right: 5px;
            }

        }
    }
}

.image-box-style5-noidung-giua {
    .image-box-style5-hinh-anh {
        margin-top: -150px !important;
    }

    .image-box-style5-noidung {

        h1,
        h2,
        h3,
        h4,
        h5 {
            font-weight: bolder;
            color: var(--primary-color);
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: 576px) {
    .image-box-style5-noidung-trai {
        .image-box-style5-hinh-anh {
            margin-top: 10px;
            margin-left: 0;
        }

        img {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
    }

    .image-box-style5-noidung-phai {
        .image-box-style5-hinh-anh {
            margin-bottom: 10px;
        }

        .image-box-style5-bg {
            margin-left: 0px;
        }
    }

    .image-box-style5-noidung-giua {
        .image-box-style5-hinh-anh {
            margin-top: 0 !important;
        }
    }
}




/* --page he-thogn-quan-tri */
.he-thong-quan-tri-dinh-kem {
    .row:nth-child(even) {
        flex-direction: row-reverse;
        /* Đảo ngược thứ tự cho hàng chẵn */
    }
}



/* -- tin tuc card */

.tin-tuc-item {
    transition: transform 0.3s ease;

    &:hover {
        .tin-tuc-card {
            transform: scale(1.05);
        }
    }
}

.tin-tuc-card {
    border: none;
    box-shadow: 5px 5px 15px 0px rgb(0, 0, 0, 0.3);

    .card-img-top {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: none;
    }

    .card-body {
        background-color: #fff;
        padding: 16px 24px 24px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .card-date {
        font-size: 14px;
        line-height: 21px;
        margin: 0px 0px 16px;
    }

    .card-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        margin: 0px 0px 8px;
    }

    .card-text {
        font-size: 16px;
        flex-grow: 1;
        line-height: 1.5;
    }
}

/*-- co dong --- */
.co-dong-category {
    .card {
        // background-color: #E9F3FD;
        background-color: var(--third-color)
    }
}

.co-dong-category:nth-child(2n) {
    .card {
        // background-color: #C8E0F8;
        background-color: var(--base-color);
    }
}

.danh-muc-item .card {
    box-shadow: none;
    transition: box-shadow 0.5s ease;
}

.danh-muc-item:hover .card {
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}

.danh-muc-doc-item:hover .danh-muc-doc-content {
    background-color: var(--base-color);
    color: #fff;
}

.dt-scroll-head .table-border-10px {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}

.dt-scroll-body .table-border-10px {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

.dt-container>.table-border-10px {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}

.btn-search {
    // background: #424242;
    background: #fff;
    border-radius: 0;
    border: 0;
    color: var(--base-color);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    // border-width: 1px;
    // border-style: solid;
    // border-color: #1c1c1c;
}

// .btn-search:link,
// .btn-search:visited {
//     color: #fff;
// }

// .btn-search:active,
.btn-search:hover {
    // background: #1c1c1c;
    background: white;
    color: var(--base-color);
    // color: #fff;
}

select[name='table-loc-nam'] {
    border: 0;
}

input[name='table-tim-kiem-input'] {
    border: 0;
}




/* -- Page tuyen dung */
.text-truncate-2-row {
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-width: 100%;
    white-space: pre-wrap;
}

.tuyen-dung-mo-ta-cong-viec {
    color: #000;

    p {
        margin-bottom: 10px;
    }
}

.tuyen-dung-yeu-cau-cong-viec {
    color: #000;

    p {
        margin-bottom: 10px;
    }
}

.tuyen-dung-lien-he {
    color: #000;
    font-size: 16px;
    ;
}

/* Page San pham*/
.san-pham-slider {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    // border-radius: 20px;
    overflow: hidden;
    -webkit-box-shadow: -7px 10px 111px -33px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -7px 10px 111px -33px rgba(0, 0, 0, 0.75);
    box-shadow: -7px 10px 111px -33px rgba(0, 0, 0, 0.75);
    background: linear-gradient(90deg, rgba(167, 162, 244, 1) 0%, rgba(112, 112, 143, 1) 35%, rgba(166, 149, 13, 1) 100%, rgba(0, 212, 255, 1) 100%);
}


.juice-wrapper {
    width: 90px;
    height: 120px;
    padding-bottom: 10px;
    z-index: 1;
}

.juice-wheel {
    width: 500px;
    height: 500px;
    // width: 400px;
    // height: 400px;
    border-radius: 50%;
    position: absolute;
    right: -250px;
    bottom: -250px;
    // right: 100px;
    // bottom: 100px;
    transform: rotate(-45deg);
    transition: transform 1s;
    z-index: 1;
}

.juice-wheel img {
    width: 320px;
}

.dynamic-juice-1 {
    position: absolute;
    // transform: rotate(-120deg);
    top: -550px;
    right: 125px;
}

.dynamic-juice-2 {
    position: absolute;
    transform: rotate(90deg);
    // transform: rotate(120deg);
    right: -350px;
    top: 0px;
}

.dynamic-juice-3 {
    position: absolute;
    transform: rotate(180deg);
    // transform: rotate(240deg);
    bottom: -550px;
    right: 50px;
}

.dynamic-juice-4 {
    position: absolute;
    transform: rotate(-90deg);
    top: 30px;
    left: -290px
}

.fruits-wheel {
    width: 700px;
    height: 700px;
    border-radius: 50%;
    position: absolute;
    top: -600px;
    left: -480px;
    transform: rotate(-45deg);
    transition: transform 1s;
    z-index: 0;

}

.fruits-wheel img {
    width: 650px;
    opacity: 40%;
}

.dynamic-fruits-1 {
    position: absolute;
    transform: rotate(90deg);
    bottom: -500px;
    left: 10px;

}

.dynamic-fruits-2 {
    position: absolute;
    transform: rotate(180deg);
    bottom: 200px;
    left: -580px;
}

.dynamic-fruits-3 {
    position: absolute;
    transform: rotate(100deg);
    top: -450px;
    left: 90px;

}

.dynamic-fruits-4 {
    position: absolute;
    bottom: 120px;
    right: -600px;
}

/* modal dang ky mua san pham - page chuoi */
.dang-ky-san-pham-modal {
    max-height: 500px;
    overflow-y: auto;
}
