/* ---- Style cho phan vung page title ---*/
@media (min-width: 768px) {
    .hagl-page-titles h1 {
        font-size:48px !important;
        font-weight: 700;

    }
    .hagl-page-titles p {
        font-size: 20px !important;
        line-height: 25px;
        font-weight: 700;
    }
}

@media (max-width: 767px) {
    .hagl-page-titles {
      h1 {
        font-size: 30px !important;
        font-weight: 700;
      }
      p {
        font-size: 15px !important;
        line-height:20px;
        font-weight: 700;
      }
    }
  }

/** style cho cac title moi muc **/
.section-title {
    position: relative;
    margin-bottom: 3rem;
    padding-bottom: 5px;
}
.section-title h3 {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 10px;
}

.section-title::before {
    position: absolute;
    content: "";
    width: 50%;
    height: 2px;
    bottom: 0;
    left: 0;
    background: var(--base-color);
}

.section-title::after {
    position: absolute;
    content: "";
    width: 28px;
    height: 28px;
    bottom: -13px;
    left:calc(50% - 13px);
    background: var(--base-color);
    border: 10px solid #FFFFFF;
    border-radius: 28px;
}

.section-title.text-center::before {
    left: 25%;
}

